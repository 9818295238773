import { AirportPlace } from './carData'
import { TripWithRenter } from './trip'
import { User } from './user'

export enum InsuranceType {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  ExtraLarge = 'ExtraLarge',
}

export enum CarProfileStatus {
  active = 'active',
  verifying = 'verifying',
  incomplete = 'incomplete',
  inactive = 'inactive',
}

export enum CarTransmission {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}

export enum CarFuelType {
  PETROL = 'petrol',
  DIESEL = 'diesel',
  ELECTRIC = 'electric',
}

export enum CanellationPolicyType {
  FLEXIBLE = 'flexible',
  MODERATE = 'moderate',
  STRICT = 'strict',
}

export enum OdometerValues {
  'O-80' = '0 - 80,000 km',
  '80-160' = '80,001 - 160,000 km',
  '160-200' = '160,001 - 200,000 km',
  '200+' = '200,001 km+',
}

export enum CarType {
  SEDAN = 'sedan',
  STATION_WAGON = 'stationWagon',
  HATCHBACK = 'hatchback',
  SPORTS = 'sports',
  CARBRIOLET = 'cabriolet',
  SUV = 'suv',
  MINIVAN = 'minivan',
  PICKUP_TRUCK = 'pickupTruck',
  LUXURY = 'luxury',
}

export enum PickupReturnAction {
  PICKUP = 'pickup',
  RETURN = 'return',
}

export interface CarPickupReturnLocationBase extends AirportPlace {
  fee: number
}

export interface CarPickupReturnLocation<
  ActionT extends PickupReturnAction = PickupReturnAction
> extends CarPickupReturnLocationBase {
  id: string
  car_profile_id: string
  action: ActionT
  place_type: 'airport'
}

export type CarPickupReturnLocationCreationData = Pick<
  CarPickupReturnLocation,
  'geocoding' | 'fee'
>

export interface CarFeatures {
  gps: boolean | null
  seats: number | null
  doors: number | null
  audio_input: boolean | null
  tools: boolean | null
  first_aid_box: boolean | null
  long_term_car: boolean | null
  pet_friendly: boolean | null
  special_occasion: boolean | null
  smoking_friendly: boolean | null
  air_conditioner: boolean | null
  infant_car_seat: boolean | null
  bluetooth_stereo: boolean | null
  wifi_internet: boolean | null
  fire_extinguisher: boolean | null
}

export interface CarPremiumFeatures {
  chauffeur_service: boolean | null
  chauffeur_service_mandatory: boolean | null
  chauffeur_service_price: number | null
  full_tank: boolean | null
  full_tank_price: number | null
  airport_pickup: boolean | null
  airport_dropoff: boolean | null
  decoration: boolean | null
  decoration_price: number | null
}

export interface InsuranceSecurityDeposits {
  deposit: number | null
  deposit_mark: boolean | null
}

export type CarCarPremiumFeaturesNames = keyof Pick<
  CarPremiumFeatures,
  | 'chauffeur_service'
  | 'full_tank'
  | 'decoration'
  | 'airport_dropoff'
  | 'airport_pickup'
>

export enum CarImageType {
  FRONT_IMAGE = 'frontImage',
  BACK_IMAGE = 'backImage',
  LEFT_IMAGE = 'leftImage',
  RIGHT_IMAGE = 'rightImage',
  FRONT_SALON_IMAGE = 'frontSalonImage',
  BACK_SALON_IAMGE = 'backSalonImage',
  TOP_IMAGE = 'topImage',
}

export interface PermitImage {
  public_id: string
  secure_url: string
  type: string
}

export interface CarImage {
  id: string
  car_profile_id: string
  public_id: string
  secure_url: string
  type: CarImageType
  createdAt: string
  updatedAt: string
}

export interface CarProfile
  extends CarFeatures,
    CarPremiumFeatures,
    InsuranceSecurityDeposits {
  opt_out_from_in_app_insurance_service: boolean
  id: string
  user_id: string
  status: CarProfileStatus

  market_price: number | null
  daily_price: number | null
  monthly_price: number | null
  weekly_price: number | null

  address: string | null
  place_id: string | null

  model_id: number | null
  model_make_id: string | null
  model_name: string | null
  model_trim: string | null
  model_year: number | null
  car_type: string | null
  transmission: CarTransmission | null
  fuel_type: CarFuelType | null
  car_description: string | null
  plate_number: string | null
  coverage_package: string | null
  advance_notice: string | null
  cancellation_policy_type: CanellationPolicyType | null

  daily_distance_limit: number | null
  extra_distance_charge: number | null
  // favorite: boolean

  host_availability_end: string | null
  host_availability_start: string | null
  identity_country: string | null
  images: CarImage[]
  location: { lat: number; lng: number }
  longest_possible_trip: number | null
  min_age_requirement: number | null

  no_branded_title: boolean | null
  odometer: OdometerValues | null
  ownership_permit: PermitImage[] | null
  ownership_permit_verified: boolean
  ownership_permit_verified_at: null | string

  shortest_possible_trip: number | null
  terms_and_conditions: boolean | null

  updated_at: string
  created_at: string

  is_extra_insurance_available: boolean
}

export type InsuranceRequestsCreate = {
  MarketPrice: number
  RegistrationNumber: string
  BrandID: number
  ModelID: number
  VehicleTypeID: number
  Vin: string
  Chassis: string
  EngineNumber: string
  YearOfManufacture: number
  EngineVolume: number
  NumberOfSeats: number
}

export type InsuranceRequestsReturn = {
  id: string
  car_profile_id: string
  market_price: number
  registration_number: string
  brand_id: number
  model_id: number
  vehicle_type_id: number
  vin: string
  chassis: string
  engine_number: string
  engine_volume: number
  number_of_seats: number
  created_at: string
  updated_at: string
  year_of_manufacture: number
  type: typeof InsuranceType[keyof typeof InsuranceType]
  status: string
}

export interface CarProfileFull extends CarProfile {
  user: User
  booking_status: string
  trips: TripWithRenter[]
  airport_pickup_locations: CarPickupReturnLocation<PickupReturnAction.PICKUP>[]
  airport_return_locations: CarPickupReturnLocation<PickupReturnAction.RETURN>[]
  insurance_request: InsuranceRequestsReturn
}

export type CarProfileUpdateableParams = Partial<
  Pick<
    CarProfile,
    // Car data
    | 'opt_out_from_in_app_insurance_service'
    | 'model_make_id'
    | 'model_name'
    | 'model_year'
    | 'images'
    | 'odometer'
    | 'transmission'
    | 'fuel_type'
    | 'car_type'
    | 'identity_country'
    | 'plate_number'
    | 'no_branded_title'
    | 'car_description'
    | 'host_availability_start'
    | 'host_availability_end'
    | 'daily_price'
    | 'weekly_price'
    | 'monthly_price'
    | 'market_price'
    | 'min_age_requirement'
    | 'advance_notice'
    | 'shortest_possible_trip'
    | 'longest_possible_trip'
    | 'daily_distance_limit'
    | 'extra_distance_charge'
    | 'cancellation_policy_type'
    | 'is_extra_insurance_available'
    //Features
    | 'gps'
    | 'seats'
    | 'doors'
    | 'audio_input'
    | 'tools'
    | 'first_aid_box'
    | 'long_term_car'
    | 'pet_friendly'
    | 'special_occasion'
    | 'chauffeur_service'
    | 'chauffeur_service_price'
    | 'chauffeur_service_mandatory'
    | 'full_tank'
    | 'full_tank_price'
    | 'place_id'
    | 'address'
    | 'airport_pickup'
    | 'airport_dropoff'
    | 'smoking_friendly'
    | 'air_conditioner'
    | 'infant_car_seat'
    | 'bluetooth_stereo'
    | 'wifi_internet'
    | 'fire_extinguisher'
  > & {
    airport_pickup_locations: CarPickupReturnLocationCreationData[]
    airport_return_locations: CarPickupReturnLocationCreationData[]
  }
>

export type CarProfileUpdateableKeys = keyof CarProfileUpdateableParams
